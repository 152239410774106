.search-row{
    display: flex;
    gap: 10px;
    align-items: center;
}
.search-row label{
    font-weight: 500;
}
.course_link{
    cursor: pointer;
    font-weight: bold;
}
.course_link:hover{
    color: rgb(0, 128, 201);
}
.grading-course-head-row{
    display: flex;
    gap: 30px;
}
.grading-course-head-row h3{
    display: inline-block;
    margin-right: 1.875rem;
}