.input-box-e {
    display: inline-block;
    margin: -43px 250px;
    padding: 0px 21px;
    font-weight: bold;
}



.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: rgba(0, 171, 182, 1) !important;

}



.ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
    border-bottom: none;
    text-decoration: none;
}

.frame-4273181841 {
    margin: 0 9.4px 22px 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 208.6px;
    box-sizing: border-box;
}

.documents {
    margin-bottom: 8px;
    display: inline-block;
    align-self: flex-start;
    overflow-wrap: break-word;
   
    font-weight: 600;
    font-size: 18px;
    line-height: 1.333;
    color: var(--neutral-500, #8E8E8E);
}

.frame-427318272 {
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: flex-start;
    width: 930.9px;
    box-sizing: border-box;
}

.frame-4273182681 {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
}

.attachment {
    position: relative;
    margin: 3px 8px 3px 0;
    width: 18px;
    height: 18px;
}

.enrolment-agreement {
    overflow-wrap: break-word;
   
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5;
    color: #5698D6;
}

.frame-4273182691 {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
}

.attachment-1 {
    position: relative;
    margin: 3px 8px 3px 0;
    width: 18px;
    height: 18px;
}

.high-school-diploma-and-transcripts {
    overflow-wrap: break-word;
   
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5;
    color: #5698D6;
}

.frame-427318270 {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
}

.attachment-2 {
    position: relative;
    margin: 3px 8px 3px 0;
    width: 18px;
    height: 18px;
}

.transcripts-from-higher-education {
    overflow-wrap: break-word;
   
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5;
    color: #5698D6;
}

.frame-427318271 {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
}

.attachment-3 {
    position: relative;
    margin: 3px 8px 3px 0;
    width: 18px;
    height: 18px;
}

.resume {
    overflow-wrap: break-word;
   
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5;
    color: #5698D6;
}

.frame-427318273 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: flex-start;
    width: 477.4px;
    box-sizing: border-box;
}

.frame-4273182682 {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
}

.attachment-4 {
    position: relative;
    margin: 3px 8px 3px 0;
    width: 18px;
    height: 18px;
}

.passport {
    overflow-wrap: break-word;
   
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5;
    color: #5698D6;
}

.frame-4273182692 {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
}

.attachment-5 {
    position: relative;
    margin: 3px 8px 3px 0;
    width: 18px;
    height: 18px;
}

.proof-of-english {
    overflow-wrap: break-word;
   
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5;
    color: #5698D6;
}

.frame-4273182701 {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
}

.attachment-6 {
    position: relative;
    margin: 3px 8px 3px 0;
    width: 18px;
    height: 18px;
}

.essay-explaining-interest {
    overflow-wrap: break-word;
   
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5;
    color: #5698D6;
}

.frame-427318260 {
    margin-bottom: 28px;
    display: flex;
    align-items: flex-start;
    /* flex-direction: row; */
    /* align-items: center; */
    /* align-self: flex-start; */
    width: fit-content;
    box-sizing: border-box;
}

.avatarpic {
    border-radius: 4px;
    position: relative;
    margin-right: 50px;

}

.avatarpic img {
    width: 78px;
    height: 78px;
    border-radius: 4px;
}


.frame-427318316 {
    margin-right: 68px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.aakash-gupta {
    position: absolute;
    top: 0;
    left: calc(101% + -1127px);
    margin-bottom: 7px;
    display: inline-block;
    align-self: flex-start;
    overflow-wrap: break-word;
   
    font-weight: bold;
    font-size: 24px;
    line-height: 1;
    color: var(--neutral-600, #4B4B4B);
}

.frame-427318315 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 246px;
    box-sizing: border-box;
    margin-left: 262px;
    margin-top: -91px;
}

.frame-427318259 {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.application-number {
    margin-bottom: -27px;
    display: inline-block;
    overflow-wrap: break-word;
    
    font-weight: var(--body-font-weight, 400);
    font-size: var(--body-font-size, 14px);
    line-height: var(--body-line-height, 5.571);
    color: var(--neutral-600, #4B4B4B);
}

.lu-18367 {
    align-self: flex-start;
    overflow-wrap: break-word;
   
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5;
    color: var(--neutral-600, #4B4B4B);
}

.frame-427318264 {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

.student-id {
    margin: 29px 2.7px 1px 0;
    display: inline-block;
    overflow-wrap: break-word;
    
    font-weight: var(--body-font-weight, 400);
    font-size: var(--body-font-size, 14px);
    line-height: var(--body-line-height, 1.571);
    color: var(--neutral-600, #4B4B4B);
}

.st-98890 {
    overflow-wrap: break-word;
   
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5;
    color: var(--neutral-600, #4B4B4B);
}

.frame-427318304 {
    margin-right: 19.6px;
    display: flex;
    flex-direction: column;
    width: fit-content;
    box-sizing: border-box;
}

.contact-details {
    margin-bottom: 20px;
    display: inline-block;
    align-self: flex-start;
    overflow-wrap: break-word;
   
    font-weight: 600;
    font-size: 18px;
    line-height: 1.333;
    color: var(--neutral-500, #8E8E8E);
}

.frame-427318268 {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 695.4px;
    box-sizing: border-box;
}

.frame-4273182612 {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.primary-email {
    margin-bottom: 1px;
    display: inline-block;
    align-self: flex-start;
    overflow-wrap: break-word;
    
    font-weight: var(--body-font-weight, 400);
    font-size: var(--body-font-size, 14px);
    line-height: var(--body-line-height, 1.571);
    color: var(--neutral-600, #4B4B4B);
}

.akashggmail-com {
    overflow-wrap: break-word;
   
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5;
    color: var(--neutral-600, #4B4B4B);
}

.frame-4273182642 {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.secondary-email {
    margin-bottom: 1px;
    display: inline-block;
    align-self: flex-start;
    overflow-wrap: break-word;
    
    font-weight: var(--body-font-weight, 400);
    font-size: var(--body-font-size, 14px);
    line-height: var(--body-line-height, 1.571);
    color: var(--neutral-600, #4B4B4B);
}

.akashggmail-com-1 {
    overflow-wrap: break-word;
   
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5;
    color: var(--neutral-600, #4B4B4B);
}

.frame-4273182621 {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

.phone-number {
    margin: 0 19.5px 1px 0;
    display: inline-block;
    overflow-wrap: break-word;
    
    font-weight: var(--body-font-weight, 400);
    font-size: var(--body-font-size, 14px);
    line-height: var(--body-line-height, 1.571);
    color: var(--neutral-600, #4B4B4B);
}

.container-1 {
    overflow-wrap: break-word;
   
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5;
    color: var(--neutral-600, #4B4B4B);
}

.frame-4273182631 {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

.emergency-mobile {
    margin: 0 6px 1px 0;
    display: inline-block;
    overflow-wrap: break-word;
    
    font-weight: var(--body-font-weight, 400);
    font-size: var(--body-font-size, 14px);
    line-height: var(--body-line-height, 1.571);
    color: var(--neutral-600, #4B4B4B);
}

.container-2 {
    overflow-wrap: break-word;
   
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5;
    color: var(--neutral-600, #4B4B4B);
}

.frame-427318302 {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    width: fit-content;
    box-sizing: border-box;
}

.current-mailing-address {
    margin-bottom: 6px;
    display: inline-block;
    align-self: flex-start;
    overflow-wrap: break-word;
   
    font-weight: 500;
    font-size: 14px;
    line-height: 1.714;
    color: var(--neutral-500, #8E8E8E);
}

.bristol-avenue-broad-street-4-th-lane-new-york-usa-99090 {
    overflow-wrap: break-word;
   
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5;
    color: var(--neutral-600, #4B4B4B);
}

.frame-427318303 {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    width: fit-content;
    box-sizing: border-box;
}

.home-address {
    margin-bottom: 6px;
    display: inline-block;
    align-self: flex-start;
    overflow-wrap: break-word;
   
    font-weight: 500;
    font-size: 14px;
    line-height: 1.714;
    color: var(--neutral-500, #8E8E8E);
}

.b-22-krishna-colony-manohar-nagar-jaipur-india-302025 {
    overflow-wrap: break-word;
   
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5;
    color: var(--neutral-600, #4B4B4B);
}

.line-1 {
    background: #E1E1E1;
    margin-bottom: 31px;
    width: 1472px;
    height: 1px;
}

.frame-427318184 {
    border-radius: 4px;
    background: var(--white, #FFFFFF);
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    width: fit-content;
    box-sizing: border-box;
}

.frame-427318301 {
    margin: 0 20.6px 26px 0;
    display: flex;
    flex-direction: column;
    width: fit-content;
    box-sizing: border-box;
}

.personal-details {
    margin-bottom: 20px;
    display: inline-block;
    align-self: flex-start;
    overflow-wrap: break-word;
   
    font-weight: 600;
    font-size: 18px;
    line-height: 1.333;
    color: var(--neutral-500, #8E8E8E);
}

.frame-427318267 {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 694.4px;
    box-sizing: border-box;
}

.frame-427318261 {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.birth-date {
    margin-bottom: 1px;
    display: inline-block;
    align-self: flex-start;
    overflow-wrap: break-word;
    
    font-weight: var(--body-font-weight, 400);
    font-size: var(--body-font-size, 14px);
    line-height: var(--body-line-height, 1.571);
    color: var(--neutral-600, #4B4B4B);
}

.feb-1990 {
    overflow-wrap: break-word;
   
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5;
    color: var(--neutral-600, #4B4B4B);
}

.frame-427318262 {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.country-of-birth {
    margin-bottom: 1px;
    display: inline-block;
    overflow-wrap: break-word;
    
    font-weight: var(--body-font-weight, 400);
    font-size: var(--body-font-size, 14px);
    line-height: var(--body-line-height, 1.571);
    color: var(--neutral-600, #4B4B4B);
}

.india {
    align-self: flex-start;
    overflow-wrap: break-word;
   
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5;
    color: var(--neutral-600, #4B4B4B);
}

.frame-427318263 {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.birth-placecity {
    margin-bottom: 1px;
    display: inline-block;
    overflow-wrap: break-word;
    
    font-weight: var(--body-font-weight, 400);
    font-size: var(--body-font-size, 14px);
    line-height: var(--body-line-height, 1.571);
    color: var(--neutral-600, #4B4B4B);
}

.jaipur {
    align-self: flex-start;
    overflow-wrap: break-word;
   
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5;
    color: var(--neutral-600, #4B4B4B);
}

.frame-427318265 {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

.student-category {
    margin-bottom: 1px;
    display: inline-block;
    overflow-wrap: break-word;
    
    font-weight: var(--body-font-weight, 400);
    font-size: var(--body-font-size, 14px);
    line-height: var(--body-line-height, 1.571);
    color: var(--neutral-600, #4B4B4B);
}

.international {
    margin-right: 14.4px;
    overflow-wrap: break-word;
   
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5;
    color: var(--neutral-600, #4B4B4B);
}

.frame-4273182671 {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

.gender {
    margin-bottom: 1px;
    display: inline-block;
    overflow-wrap: break-word;
    
    font-weight: var(--body-font-weight, 400);
    font-size: var(--body-font-size, 14px);
    line-height: var(--body-line-height, 1.571);
    color: var(--neutral-600, #4B4B4B);
}

.male {
    margin-right: 8.2px;
    overflow-wrap: break-word;
   
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5;
    color: var(--neutral-600, #4B4B4B);
}

.frame-427318269 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: flex-start;
    width: 204px;
    box-sizing: border-box;
}

.frame-4273182641 {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.citizenship {
    margin-bottom: 1px;
    display: inline-block;
    overflow-wrap: break-word;
    
    font-weight: var(--body-font-weight, 400);
    font-size: var(--body-font-size, 14px);
    line-height: var(--body-line-height, 1.571);
    color: var(--neutral-600, #4B4B4B);
}

.indian {
    align-self: flex-start;
    overflow-wrap: break-word;
   
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5;
    color: var(--neutral-600, #4B4B4B);
}

.frame-4273182611 {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.ssn {
    margin-bottom: 1px;
    display: inline-block;
    align-self: flex-start;
    overflow-wrap: break-word;
    
    font-weight: var(--body-font-weight, 400);
    font-size: var(--body-font-size, 14px);
    line-height: var(--body-line-height, 1.571);
    color: var(--neutral-600, #4B4B4B);
}

.container {
    overflow-wrap: break-word;
    font-family: roboto;
    /* font-weight: bold; */
    font-size: 16px;
    line-height: 1.5;
    color: var(--neutral-600, #4B4B4B);
}

.tablehedding {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -32px;
    background: #f2f2f2;
    max-height: 50px;
    padding-bottom: 36px;
    padding-left: 15px;
}


.Earned {
    margin-left: 271px;
}

.aakash-gupta {
    margin-left: -106px;
    margin-top: 92px;
    font-family: roboto;
    font-size: x-large;
}

.img {
   
    position: relative;
    top: -31px;
    width: 42px;
    height: 43px;
    border: 1px solid black;
}

.Aakash-gupta {

    margin-bottom: 7px;
    display: inline-block;
    align-self: flex-start;
    overflow-wrap: break-word;
   
    font-weight: bold;
    font-size: 24px;
    line-height: 1;
    color: var(--neutral-600, #4B4B4B);
}

.Application-number {
    margin-bottom: 7px;
    display: inline-block;
    align-self: flex-start;
    font-family: 'Roboto';

}

.Student-ID {
    margin-bottom: 7px;
    display: inline-block;
    align-self: flex-start;
    /* font-family: system-ui; */
    font-family: 'Roboto';
}

.program-detail {
    overflow-wrap: break-word;
    font-family: roboto;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.333;
    color: var(--neutral-500, #8E8E8E);
    position: relative;
    top: -125px;
    
}

.container {
    display: flex;
}

.container>div {
    margin-right: 45px;
}


.student-type,
.student-type1,
.student-type2,
.student-type3 {
    color: grey;
    font-family: 'Roboto';

}

.india1,
.india,
.india2,
.india3 {
    margin-left: 1px;
    font-weight: bold;font-family: 'Roboto';
}

.details {
    overflow-wrap: break-word;
    font-family: roboto;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.333;
    color: var(--neutral-500, #8E8E8E);
    position: relative;
    top: 14px;
}

.credit {
    position: relative;
    position: relative;
    left: -390px;
}

.dot1 {
    border: 1px solid black;
    width: 18px;
    height: 19px;
    padding-left: 4px;
    padding-top: -1px;
    background-color: #1a4770;
    color: floralwhite;
    border-radius: 50px;
    margin-left: 113px;

    position: relative;
    top: -153px;
}

.dot2 {
    border: 1px solid black;
    width: 18px;
    height: 19px;
    padding-left: 4px;
    padding-top: -1px;
    background-color: #1a4770;
    color: floralwhite;
    border-radius: 50px;
    margin-left: 113px;

    position: relative;
    top: -117px;
}


.dot3 {


    background-color: #00FFFF;

    border-radius: 50px;
    margin-left: 568px;

    position: relative;
    top: -16px;
    width: 19px;

    height: 19px;
}


.dot4 {



    margin-left: 600px;

    position: relative;
    top: -35px;
    width: 19px;

    height: 19px;
    color: grey;
}


.row31{
    position: relative;
    top: -31px;
    left: 19px;
    color: grey; 
}

.row32{
    position: relative;
    top: -31px;
    left: 336px;
    color: grey; 
}

.row33{
    position: relative;
    top: -31px;
    left:575px;
    color: grey; 
}