.TransferredCourse-row{
    display: flex;
    gap: 10px;
    align-items: center;
}
.TransferredCourse-row label{
    font-size: 10px;
    
}
:where(.css-dev-only-do-not-override-11xg00t).ant-picker {
    box-sizing: border-box;
    margin: 0;
    padding: 8px 11px 8px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1;
    list-style: none;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
    position: relative;
    display: inline-flex;
    align-items: center;
    border-radius: 6px;
    transition: border 0.2s,box-shadow 0.2s,background 0.2s;
}