.Student_attend_Class_table {
    width: 1250px;
    height: 131px;
}

.Student_attend_Class_left {
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: 500;
    font-weight: bold;
    text-align: left;
    color: rgba(59, 130, 246, 1);
}

.Staus_left_Head {
    margin-left: 10px;
}

.Lu_sis_ {
    width: 1,091px;
    border-radius: 4px;
    margin: 10px 0;
    background-color: rgb(255, 242, 224);
    padding: 20px 0px 20px 20px;
}

.Icon_Styling_left {
    margin-right: 10px;
    padding: 3px;
    color: white;
    background: rgba(182, 65, 65, 1);
    /* width: 1.2em !important;
    height: 1.5em !important; */

}

.Icon_Styling_right {
    color: white;
    background: rgba(182, 65, 65, 1);
    margin-left: 10px;
    padding: 2px;
    /* width: 1.2em !important;
    height: 1.5em !important; */
}

.ant-table-thead
{
    background-color: #fff !important;
}

