.basic-info-flex{
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: space-between;
}
.basic-info-flex div:first-child{
    max-width: 150px;
    border: 1px solid #ccc;
    overflow: hidden;
    height: 150px;
    border-radius: 8px;
    position: relative;
}
.basic-info-flex div:first-child img{
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.basic-info-flex div{
    flex-basis: 500px;
}
:where(.css-dev-only-do-not-override-11xg00t).ant-select-single {
    font-size: 14px;
    height: 40px;
}
.transferred-course{
    display: block;
    padding: 5px;
    margin: 15px 0px;
    border-radius: 5px;
}
.transferred-course.active{
    background-color: #f5f4f4;
}