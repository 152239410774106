


.custom-address-column  {
    width: 150px; 
}

.custom-department-column {
    width: 150px; 
    height: auto;
}
